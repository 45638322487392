



































































import { Messages } from "@/models/enums/messages.enum";
import { DataResponseCalendar } from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataRequestCLosePeriod,
  RequestUpdateClosePeriod,
} from "@/models/interface/settings.interface";
import { assetsServices } from "@/services/assets.service";
import { settingsServices } from "@/services/settings.service";
import { Vue, Component } from "vue-property-decorator";
@Component
export default class ClosePeriode extends Vue {
  loadingCalendar = false as boolean;
  dataCalendar = [] as DataResponseCalendar[];
  loadingOpen = false as boolean;
  loadingClose = false as boolean;
  form = this.$form.createForm(this, { name: "closePeriod" });
  disabled = {
    close: false as boolean,
  };
  dataDetails = "" as any;
  formRules = {
    period: {
      label: "Period AR",
      name: "period",
      placeholder: "lbl_type_to_find_placeholder",
      decorator: [
        "period",
        {
          rules: [
            {
              required: true,
              message: Messages.VALIDATION_REQUIRED_ERROR,
            },
          ],
        },
      ],
    },
    status: {
      label: "Status",
      name: "status",
      placeholder: "Insert Status",
      decorator: [
        "status",
        {
          rules: [
            {
              required: false,
              message: Messages.VALIDATION_REQUIRED_ERROR,
            },
          ],
        },
      ],
    },
  };
  mounted() {
    this.getListCalendar("");
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  close(): void {
    const dataPush = [] as DataRequestCLosePeriod[];
    const dataObj = {
      calendarClosePeriodId: this.dataDetails.id,
      closePeriod: true,
    };
    dataPush.push(dataObj);
    const dataPost = {
      requestBody: dataPush,
    } as RequestUpdateClosePeriod;
    this.loadingClose = true;
    settingsServices
      .updateCalendarClosePeriod(dataPost)
      .then(() => {
        this.$notification.success({
          description: Messages.UPDATE_SUCCESS,
          message: "Success",
          duration: 30,
        });
        this.getListCalendar("");
        this.form.resetFields();
      })
      .catch(e => {
        this.isFormSubmitted = false;
        this.$notification.error({
          description: Messages.UPDATE_FAIL,
          message: "Error",
          duration: 30,
        });
        this.$notification.error({
          description: e.details,
          message: "Error",
          duration: 30,
        });
      })
      .finally(() => (this.loadingClose = false));
  }
  handleCancel() {
    this.form.resetFields();
    this.$router.go(-1);
  }
  changeCalendar(value) {
    if (value) {
      this.form.setFieldsValue({
        status: !this.dataCalendar
          .find(item => item.month == value)
          ?.calendarClosePeriods.find(data => data.modulName == "AR")
          ?.closePeriod
          ? "OPEN"
          : "CLOSE",
      });
      this.dataDetails = this.dataCalendar
        .find(item => item.month == value)
        ?.calendarClosePeriods.find(data => data.modulName == "AR");
      if (
        !this.dataCalendar
          .find(item => item.month == value)
          ?.calendarClosePeriods.find(data => data.modulName == "AR")
          ?.closePeriod
      )
        this.disabled.close = false;
      else this.disabled.close = true;
    }
  }
  getListCalendar(valueSearch) {
    let params = {
      page: 0,
      limit: 10,
    } as RequestQueryParamsModel;
    if (valueSearch) params.search = `month~*${valueSearch}*`;
    this.loadingCalendar = true;
    let dataPeriode = [] as any;
    this.dataCalendar = [];
    assetsServices
      .listCalendar(params)
      .then(data => {
        data.data.forEach(element => {
          if (!element.month.includes("Adj")) dataPeriode.push(element);
        });
        this.dataCalendar = dataPeriode;
      })
      .finally(() => (this.loadingCalendar = false));
  }
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
